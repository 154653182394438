import React from "react";
import './InfoUser.scss'
import {
  Container,
  Row,
  Col,
  FormControl,
  Button,
  InputGroup,
} from "react-bootstrap";
import IconUser from "../IconSvg/IconUser";
import IconLock from "../IconSvg/IconLock";
import IconSupport from "../IconSvg/IconSupport";
import IconMicrosoft from "../IconSvg/IconMicrosoft";

export default function InfoUser() {
  
    return (
      <>
        <section className="infoUser">
          <div className="containerFull">          
          <Row>
            <Col md={6}>              
             <Row>
               <Col md={1} className="hold-circle">
                <span className="circle"></span>
               </Col>
               <Col>
               <section className="boxUser">
                <h2> 
                  Olá Felipe
                </h2>
                <p>
                  <IconMicrosoft />
                  felipecosta@brainz.group</p>
              </section></Col>
             </Row>
            </Col>
            <Col md={6}>
              <section className="boxUser">
                <nav className="NavMenuIntern">
                  <ul>
                    <li>
                      <a href="#">
                        <IconUser />
                        Meus dados
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <IconLock />
                        Alterar senha</a>
                    </li>
                    <li>
                      <a href="#">
                        <IconSupport />
                        Suporte</a>
                    </li>
                  </ul>
                </nav>
              </section>
            </Col>
          </Row>
          </div>
        </section>
      </>
    );
  }
