import React from 'react';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

class PageLoader extends React.Component{
    constructor(props) {
        super(props);
        this.state = {};
    }
    render(){
        return(
            <div id={"loader"} className={'loader-background'}>
                      <Loader
                        type="TailSpin"
                        color="#6468C8"
                        height={100}
                        width={100}
                        className={"mx-auto"}
                    />
            </div>
        );

    }
}

export default PageLoader;