function NotFound() {
    return (
        <div>
            <div className="title">Tela ainda não desenvolvida</div>
        </div>
    )

}



export default NotFound;
