import React from "react";

export class Logo extends React.Component {
  render() {
    return (
      <>
       
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" >
<rect width="20" height="20" fill="#E5E5E5"/>
<rect width="1280" height="1029" transform="translate(-55 -431)" fill="#F9F9F9"/>
<rect x="-14.5" y="-154.5" width="284" height="200" rx="7.5" fill="white" stroke="#D7D5D5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.25 17.5C0.25 18.0967 0.487053 18.669 0.90901 19.091C1.33097 19.5129 1.90326 19.75 2.5 19.75H14.5C15.0967 19.75 15.669 19.5129 16.091 19.091C16.5129 18.669 16.75 18.0967 16.75 17.5V11.5C16.75 11.3011 16.671 11.1103 16.5303 10.9697C16.3897 10.829 16.1989 10.75 16 10.75C15.8011 10.75 15.6103 10.829 15.4697 10.9697C15.329 11.1103 15.25 11.3011 15.25 11.5V17.5C15.25 17.6989 15.171 17.8897 15.0303 18.0303C14.8897 18.171 14.6989 18.25 14.5 18.25H2.5C2.30109 18.25 2.11032 18.171 1.96967 18.0303C1.82902 17.8897 1.75 17.6989 1.75 17.5V5.5C1.75 5.30109 1.82902 5.11032 1.96967 4.96967C2.11032 4.82902 2.30109 4.75 2.5 4.75H8.5C8.69891 4.75 8.88968 4.67098 9.03033 4.53033C9.17098 4.38968 9.25 4.19891 9.25 4C9.25 3.80109 9.17098 3.61032 9.03033 3.46967C8.88968 3.32902 8.69891 3.25 8.5 3.25H2.5C1.90326 3.25 1.33097 3.48705 0.90901 3.90901C0.487053 4.33097 0.25 4.90326 0.25 5.5V17.5ZM10.75 1C10.75 0.801088 10.829 0.610322 10.9697 0.46967C11.1103 0.329018 11.3011 0.25 11.5 0.25H19C19.1989 0.25 19.3897 0.329018 19.5303 0.46967C19.671 0.610322 19.75 0.801088 19.75 1V8.5C19.75 8.69891 19.671 8.88968 19.5303 9.03033C19.3897 9.17098 19.1989 9.25 19 9.25C18.8011 9.25 18.6103 9.17098 18.4697 9.03033C18.329 8.88968 18.25 8.69891 18.25 8.5V1.75H11.5C11.3011 1.75 11.1103 1.67098 10.9697 1.53033C10.829 1.38968 10.75 1.19891 10.75 1Z" fill="#0F7EAA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M19.531 0.468388C19.6008 0.538056 19.6563 0.62082 19.6941 0.711937C19.7319 0.803055 19.7513 0.900737 19.7513 0.999388C19.7513 1.09804 19.7319 1.19572 19.6941 1.28684C19.6563 1.37796 19.6008 1.46072 19.531 1.53039L7.531 13.5304C7.46127 13.6001 7.37849 13.6554 7.28738 13.6932C7.19627 13.7309 7.09862 13.7503 7 13.7503C6.90139 13.7503 6.80374 13.7309 6.71263 13.6932C6.62152 13.6554 6.53873 13.6001 6.469 13.5304C6.39927 13.4607 6.34396 13.3779 6.30622 13.2868C6.26848 13.1957 6.24905 13.098 6.24905 12.9994C6.24905 12.9008 6.26848 12.8031 6.30622 12.712C6.34396 12.6209 6.39927 12.5381 6.469 12.4684L18.469 0.468388C18.5387 0.398543 18.6214 0.343129 18.7126 0.305319C18.8037 0.267509 18.9014 0.248047 19 0.248047C19.0987 0.248047 19.1963 0.267509 19.2875 0.305319C19.3786 0.343129 19.4613 0.398543 19.531 0.468388Z" fill="#0F7EAA"/>
</svg>

      </>
    );
  }
}
export default Logo;
