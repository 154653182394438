import React from "react";
import "./ProjectsActive.scss";
import {
  Container,
  Row,
  Col,
  FormControl,
  Button,
  InputGroup,
} from "react-bootstrap";
import IconLogo from "../IconSvg/IconLogo";
import IconAccess from "../IconSvg/IconAccess";
import IconModules from "../IconSvg/iconModules";
import IconGo from "../IconSvg/IconGo";
import IconBar from "../IconSvg/IconBar";
import IconSchool from "../IconSvg/iconSchool";
import IconCopy from "../IconSvg/IconCopy";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export default function ProjectsActive() {
    return (
      <>
        <section className="MainBox">
          <section className="containerFull">
            <Row className="projectsActive">
              <Col md={4}>
                <h2>
                  <IconBar />
                  Projetos Ativos
                </h2>
                <section className="boxDefault">
                  <Row>
                    <Col md={7}>
                      <p>Integração com Microsoft Teams</p>
                      <p>Etapa atual: desenvolvimento</p>
                      <a href="#">Detalhes do projeto</a>
                    </Col>
                    <Col>
                      <CircularProgressbar value={47} text={`${47}%`} />
                   </Col>
                  </Row>
                </section>
                <section className="boxDefault">
                  <Row>
                    <Col md={7}>
                      <p>Integração com Microsoft Teams</p>
                      <p>Etapa atual: desenvolvimento</p>
                      <a href="#">Detalhes do projeto</a>
                    </Col>
                    <Col>
                      <CircularProgressbar className="circularGreen" value={65} text={`${65}%`} />
                   </Col>
                  </Row>
                </section>
              </Col>
              <Col md={4}>
                <h2>
                  <IconCopy />
                  Documentos
                </h2>
                <section className="boxDefaultSmall">
                  <Row>
                    <Col md={3}>
                      <span className="spNew">Novo</span>
                    </Col>
                    <Col>
                      <p>Recibo de compra de equipamentos</p>
                      14/07/2022
                    </Col>
                  </Row>
                </section>
                <section className="boxDefaultSmall">
                  <Row>
                    <Col md={3}>
                      <span className="spNew">Novo</span>
                    </Col>
                    <Col>
                      <p>Recibo de compra de equipamentos</p>
                      14/07/2022
                    </Col>
                  </Row>
                </section>
                <section className="boxDefaultSmall">
                  <Row>
                    <Col>
                      <p>Proposta comercial</p>
                      14/07/2022
                    </Col>
                  </Row>
                </section>
              </Col>
              <Col md={4}>
                <h2>
                  <IconSchool />
                  Instituição
                </h2>
                <section className="boxDefault">
                  <p>Universidade Federal do Paraná</p>
                </section>
              </Col>
            </Row>
          </section>
        </section>
      </>
    );
  }
