import React, { useEffect, useState } from "react";
import InfoUser from "./../../components/InfoUser/InfoUser";
import MainBox from './../../components/MainBox/MainBox';
import ProjectsActive from './../../components/ProjectsActive/ProjectsActive';
export default function Home() {
    return (
        <div>
            <InfoUser />
            <MainBox />
            <ProjectsActive />
        </div>
    )
}