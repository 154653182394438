import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { BsExclamationCircle, BsPlus, BsThreeDotsVertical } from "react-icons/bs";
import styled from "styled-components";
import ConfirmModal from "../../components/Modals/ConfirmModal";
import RegisterInstitution from "./Modals/RegisterInstitution";

export default function Institutions() {

    const [activeModal, setActiveModal] = useState<number>(0)
    const [previousActiveModal, setPreviousActiveModal] = useState(0)


    function handleModalClose() {
        setActiveModal(0)
    }
    function handleReturnModal() {
        setActiveModal(previousActiveModal)
    }
    return (
        <BodyTasks className="container-fluid mt-0 px-5" >
            <InitialHeaderStyles>
                <Col md={12}>
                    <Row className="ml-1"><h4 style={{ color: 'blue' }}>Início  &gt;&nbsp;</h4> <h4> Instituições</h4></Row>
                </Col>
                <Col md={9} className="mt-2" >
                    <h2>Instituições</h2>
                </Col>
                <Col md={3} className="" style={{ textAlign: 'right' }}>
                    <Button className="button-add-item" aria-label="Adicionar"
                        onClick={() => setActiveModal(1)}
                    >
                        <BsPlus className="mr-1" fontSize={28} fontWeight={700} height={24} width={24}></BsPlus>
                        Cadastrar nova Instituição
                    </Button>
                </Col>
                <Col md={12} >
                    <h3> Adicione uma nova instituição ou confira todas as instituições cadastradas.</h3>
                </Col>
            </InitialHeaderStyles>
            <FiltersStyleComponent className="w-100 search-filter">
                <Col md={6}>
                    <Form.Group>
                        <div className="search-filter">
                            <Form.Control
                                className="filter-name"
                                //value={searchFilter}
                                //onChange={(e) => { handleSearchFilterOnChange(e.target.value) }}
                                type="text" placeholder="Buscar por Nome ou CNPJ" size="sm" >
                            </Form.Control>
                        </div>
                    </Form.Group>
                </Col>
            </FiltersStyleComponent>
            <Col md={12}>
                <TableStyles>
                    <Table className="table-list" striped borderless hover responsive>
                        <thead>
                            <tr className="title-table" style={{ textAlignLast: 'start' }}>
                                <th className="border-radius-left">Empresa</th>
                                <th>CNPJ</th>
                                <th>Provedor</th>
                                <th>Números usuários</th>
                                <th>Produtos ativos</th>
                                <th className="border-radius-right" ></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="table-values">
                                <td style={{ textAlignLast: 'start' }}>Big Brain</td>
                                <td style={{ textAlignLast: 'start' }}>53.521.654/0001-99</td>
                                <td style={{ textAlignLast: 'start' }}>Google</td>
                                <td style={{ textAlignLast: 'start' }}>1234</td>
                                <td style={{ textAlignLast: 'start' }}>12</td>
                                <td style={{ textAlignLast: 'auto' }}>
                                    <BsThreeDotsVertical size="24" />
                                </td>
                            </tr>
                            <tr className="table-values">
                                <td style={{ textAlignLast: 'start' }}>Big Brain</td>
                                <td style={{ textAlignLast: 'start' }}>53.521.654/0001-99</td>
                                <td style={{ textAlignLast: 'start' }}>Google</td>
                                <td style={{ textAlignLast: 'start' }}>1234</td>
                                <td style={{ textAlignLast: 'start' }}>12</td>
                                <td style={{ textAlignLast: 'auto' }}>
                                    <BsThreeDotsVertical size="24" />
                                </td>
                            </tr>
                            <tr className="table-values">
                                <td style={{ textAlignLast: 'start' }}>Big Brain</td>
                                <td style={{ textAlignLast: 'start' }}>53.521.654/0001-99</td>
                                <td style={{ textAlignLast: 'start' }}>Google</td>
                                <td style={{ textAlignLast: 'start' }}>1234</td>
                                <td style={{ textAlignLast: 'start' }}>12</td>
                                <td style={{ textAlignLast: 'auto' }}>
                                    <BsThreeDotsVertical size="24" />
                                </td>
                            </tr>
                            <tr className="table-values">
                                <td style={{ textAlignLast: 'start' }}>Big Brain</td>
                                <td style={{ textAlignLast: 'start' }}>53.521.654/0001-99</td>
                                <td style={{ textAlignLast: 'start' }}>Google</td>
                                <td style={{ textAlignLast: 'start' }}>1234</td>
                                <td style={{ textAlignLast: 'start' }}>12</td>
                                <td style={{ textAlignLast: 'auto' }}>
                                    <BsThreeDotsVertical size="24" />
                                </td>
                            </tr>
                            <tr className="table-values">
                                <td style={{ textAlignLast: 'start' }}>Big Brain</td>
                                <td style={{ textAlignLast: 'start' }}>53.521.654/0001-99</td>
                                <td style={{ textAlignLast: 'start' }}>Google</td>
                                <td style={{ textAlignLast: 'start' }}>1234</td>
                                <td style={{ textAlignLast: 'start' }}>12</td>
                                <td style={{ textAlignLast: 'auto' }}>
                                    <BsThreeDotsVertical size="24" />
                                </td>
                            </tr>
                            <tr className="table-values">
                                <td style={{ textAlignLast: 'start' }}>Big Brain</td>
                                <td style={{ textAlignLast: 'start' }}>53.521.654/0001-99</td>
                                <td style={{ textAlignLast: 'start' }}>Google</td>
                                <td style={{ textAlignLast: 'start' }}>1234</td>
                                <td style={{ textAlignLast: 'start' }}>12</td>
                                <td style={{ textAlignLast: 'auto' }}>
                                    <BsThreeDotsVertical size="24" />
                                </td>
                            </tr>
                            <tr className="table-values">
                                <td style={{ textAlignLast: 'start' }}>Big Brain</td>
                                <td style={{ textAlignLast: 'start' }}>53.521.654/0001-99</td>
                                <td style={{ textAlignLast: 'start' }}>Google</td>
                                <td style={{ textAlignLast: 'start' }}>1234</td>
                                <td style={{ textAlignLast: 'start' }}>12</td>
                                <td style={{ textAlignLast: 'auto' }}>
                                    <BsThreeDotsVertical size="24" />
                                </td>
                            </tr>
                            <tr className="table-values">
                                <td style={{ textAlignLast: 'start' }}>Big Brain</td>
                                <td style={{ textAlignLast: 'start' }}>53.521.654/0001-99</td>
                                <td style={{ textAlignLast: 'start' }}>Google</td>
                                <td style={{ textAlignLast: 'start' }}>1234</td>
                                <td style={{ textAlignLast: 'start' }}>12</td>
                                <td style={{ textAlignLast: 'auto' }}>
                                    <BsThreeDotsVertical size="24" />
                                </td>
                            </tr>
                            <tr className="table-values">
                                <td style={{ textAlignLast: 'start' }}>Big Brain</td>
                                <td style={{ textAlignLast: 'start' }}>53.521.654/0001-99</td>
                                <td style={{ textAlignLast: 'start' }}>Google</td>
                                <td style={{ textAlignLast: 'start' }}>1234</td>
                                <td style={{ textAlignLast: 'start' }}>12</td>
                                <td style={{ textAlignLast: 'auto' }}>
                                    <BsThreeDotsVertical size="24" />
                                </td>
                            </tr>
                            <tr className="table-values">
                                <td style={{ textAlignLast: 'start' }}>Big Brain</td>
                                <td style={{ textAlignLast: 'start' }}>53.521.654/0001-99</td>
                                <td style={{ textAlignLast: 'start' }}>Google</td>
                                <td style={{ textAlignLast: 'start' }}>1234</td>
                                <td style={{ textAlignLast: 'start' }}>12</td>
                                <td style={{ textAlignLast: 'auto' }}>
                                    <BsThreeDotsVertical size="24" />
                                </td>
                            </tr>

                        </tbody>
                    </Table>
                    {/* {paging && <BrainzPagination setQueryParams={setQueryPaginationParams} paging={paging}></BrainzPagination>} */}
                </TableStyles>
            </Col>

            <RegisterInstitution activeModal={activeModal} setActiveModal={setActiveModal} setPreviousActiveModal={setPreviousActiveModal} />

            <ConfirmModal
                show={activeModal === 6}
                onHide={handleReturnModal}
                title="Deseja descartar esse cadastro?"
                subTitle="Você perderá os dados informados"
                confirmAction={handleModalClose}
                refuseAction={handleReturnModal}
                confirmText="Descartar cadastro"
                refuseText='Continuar cadastro'
                principalIcon={<BsExclamationCircle color="#A56300" size={110} />}
            />

        </BodyTasks>
    )
}
const TableStyles = styled(Row)`
margin-top: 0px;
padding-top: 0px;
.border-radius-left{
    border-top-left-radius:10px;
}
.border-radius-right{
    border-top-right-radius: 10px;
}
.title-table{
    height: 44px;
}
.table-values{
    height: 56px;
}
th{
    //text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #424040;
    background-color: #E4E3E3
}
td{
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #525252;
    
}
.bg-error-color{
    background-color: #FDF1DE;
    color:#A56300;
    }
.success-status{
    background-color: #E5F4EF;
}
.error-status{
    background-color: #FDF1DE;
}
`

const FiltersStyleComponent = styled(Row)`
margin-bottom: 0px;
padding-bottom: 0px;
.search-filter-status{
    //font-family: roboto;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #514F4E;
    
    Button{        
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 8px;
        width: 113px;
        height: 48px;
        background: #F5F5F5;
        border: 1px solid #E4E3E3;
        box-sizing: border-box;
        border-radius: 8px;
        color: #6F6F6F;        
        &:focus{
            background: #F5F5F5!important;
            border: 0.14vmin solid #0C8292!important;
            color: #6F6F6F!important;            
            box-shadow:none!important;
        }
        
        &:active{
            background: #F5F5F5!important;
            color: #6F6F6F!important;
        }
        &:hover {
            background: #F5F5F5!important;
            color: #6F6F6F!important;  
        }
        &:disabled{
            background-color: #F5F5F5!important;
            color: #6F6F6F!important;
            border: 1px solid #E4E3E3;
        }
    }
    .dropdown-toggle::after {
    display:none;
    }
}   
            .filter-name{
                background: #f5f5f5;
                border: 1px solid #E4E3E3;
                box-sizing: border-box;
                border-radius: 8px;
                flex: none;
                order: 1;
                align-self: stretch;
                flex-grow: 0;
                margin: 0px 0px;
                height: 48px;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 21px;
                color: #525252;
            }
            .search-filter-usertype{
    
    Button{        
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 8px;
        width: 171px;
        height: 48px;
        background: #F5F5F5;
        border: 1px solid #E4E3E3;
        box-sizing: border-box;
        border-radius: 8px;
        color: #6F6F6F;        
        &:focus{
            background: #F5F5F5!important;
            border: 0.14vmin solid #0C8292!important;
            color: #6F6F6F!important;            
            box-shadow:none!important;
        }
        
        &:active{
            background: #F5F5F5!important;
            color: #6F6F6F!important;
        }
        &:hover {
            background: #F5F5F5!important;
            color: #6F6F6F!important;  
        }
        &:disabled{
            background-color: #F5F5F5!important;
            color: #6F6F6F!important;
            border: 1px solid #E4E3E3;
        }
    }
}
`

const BodyTasks = styled(Col)`
                //margin-top: 2.88vmin;
                // text-align: center;
                Col{
                    text-align: left;
    }
    `

const InitialHeaderStyles = styled(Row)`
margin-top: 2.88vmin;

                .button-add-item{
    width: 235px;
    height: 48px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #2155BA;
    padding: 0px;
    background-color: #fff;
    border: 1px solid #2155BA;
    box-sizing: border-box;
    border-radius: 8px;
                &:focus{
        background: none!important;
        border: 1px solid #2155BA!important;
        color: #2155BA!important;
    }
                &:active{
        background: none!important;
        color: #2155BA!important;
    }
                &:hover {
        background: #2155BA!important;
        color: #fff!important;
        border: 1px solid #fff!important;
                .icon-plus{
            fill: #fff!important;
        }
    }
                &:disabled{
        background: none!important;
        border:#2155BA!important;
    }
}

                .col-fullscreen-button{
    tex-align: right;
}
                h2{
    font-weight: 600px;
    font-size: 20px;
    line-height: 24px;
    color: #424040;
}
                h3{
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #6F6F6F;
}h4{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #525252;
}

`