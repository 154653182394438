import React from "react";

export class Logo extends React.Component {
  render() {
    return (
      <>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.08 0.292C9.26741 0.105062 9.5213 5.6238e-05 9.786 0L22.214 0C22.4787 5.6238e-05 22.7326 0.105062 22.92 0.292L31.708 9.08C31.8949 9.26741 31.9999 9.5213 32 9.786V22.214C31.9999 22.4787 31.8949 22.7326 31.708 22.92L22.92 31.708C22.7326 31.8949 22.4787 31.9999 22.214 32H9.786C9.5213 31.9999 9.26741 31.8949 9.08 31.708L0.292 22.92C0.105062 22.7326 5.6238e-05 22.4787 0 22.214L0 9.786C5.6238e-05 9.5213 0.105062 9.26741 0.292 9.08L9.08 0.292ZM10.2 2L2 10.2V21.8L10.2 30H21.8L30 21.8V10.2L21.8 2H10.2Z"
            fill="#525252"
          />
          <path
            d="M10.5 12.066H13.14C13.14 10.504 14.056 9.298 15.86 9.298C17.23 9.298 18.486 9.984 18.486 11.634C18.486 12.904 17.738 13.488 16.556 14.376C15.21 15.354 14.144 16.496 14.22 18.35L14.234 19.276H16.848V18.566C16.848 17.13 17.394 16.712 18.868 15.594C20.086 14.668 21.356 13.64 21.356 11.482C21.356 8.46 18.804 7 16.01 7C13.358 7 10.438 8.294 10.502 12.066H10.5ZM13.624 23.098C13.624 24.164 14.474 24.952 15.644 24.952C16.862 24.952 17.7 24.164 17.7 23.098C17.7 21.994 16.86 21.218 15.642 21.218C14.474 21.218 13.624 21.994 13.624 23.098Z"
            fill="#525252"
          />
        </svg>
      </>
    );
  }
}
export default Logo;
