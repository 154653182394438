import axios from 'axios';
import Cookies from 'universal-cookie';
import { showToast } from './components/Common/Toast';

const cookie = new Cookies();
const AccountInterface = axios.create({
    baseURL: process.env.REACT_APP_ACCOUNT_URL
})
const token = cookie.get(String(process.env.REACT_APP_BRAINZ_NAME_TOKEN));

AccountInterface.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${token}`
    return config
}, function (error) {
    return Promise.reject(error);
});

AccountInterface.interceptors.response.use(
    function (config) {
        return config
    },
    function (error: any) {
        if (error.response.status === 401) {
            window.location.href = process.env["REACT_APP_ACCOUNT_REDIRECT"]!;
        }
        if (error.response.status === 404) {
            showToast("error","Houve um erro, favor tentar novamente mais tarde")
        }
        return error
    });

export default AccountInterface;
