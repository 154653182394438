import React from "react";

export class Logo extends React.Component {
  render() {
    return (
      <>
        <svg
          width="24"
          height="18"
          viewBox="0 0 24 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.469 14.0319C17.3992 13.9623 17.3437 13.8795 17.3059 13.7884C17.2681 13.6973 17.2487 13.5996 17.2487 13.5009C17.2487 13.4023 17.2681 13.3046 17.3059 13.2135C17.3437 13.1224 17.3992 13.0396 17.469 12.9699L21.4395 9.00095L17.469 5.03195C17.3282 4.89112 17.2491 4.70011 17.2491 4.50095C17.2491 4.30178 17.3282 4.11078 17.469 3.96995C17.6098 3.82912 17.8008 3.75 18 3.75C18.1992 3.75 18.3902 3.82912 18.531 3.96995L23.031 8.46995C23.1008 8.53962 23.1563 8.62238 23.1941 8.7135C23.2319 8.80462 23.2513 8.9023 23.2513 9.00095C23.2513 9.0996 23.2319 9.19728 23.1941 9.2884C23.1563 9.37952 23.1008 9.46228 23.031 9.53195L18.531 14.0319C18.4613 14.1018 18.3786 14.1572 18.2874 14.195C18.1963 14.2328 18.0986 14.2523 18 14.2523C17.9013 14.2523 17.8037 14.2328 17.7125 14.195C17.6214 14.1572 17.5387 14.1018 17.469 14.0319V14.0319Z"
            fill="#525252"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.75 9C6.75 8.80109 6.82902 8.61032 6.96967 8.46967C7.11032 8.32902 7.30109 8.25 7.5 8.25H21C21.1989 8.25 21.3897 8.32902 21.5303 8.46967C21.671 8.61032 21.75 8.80109 21.75 9C21.75 9.19891 21.671 9.38968 21.5303 9.53033C21.3897 9.67098 21.1989 9.75 21 9.75H7.5C7.30109 9.75 7.11032 9.67098 6.96967 9.53033C6.82902 9.38968 6.75 9.19891 6.75 9Z"
            fill="#525252"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3 17.25C2.40326 17.25 1.83097 17.0129 1.40901 16.591C0.987053 16.169 0.75 15.5967 0.75 15V3C0.75 2.40326 0.987053 1.83097 1.40901 1.40901C1.83097 0.987053 2.40326 0.75 3 0.75H13.5C14.0967 0.75 14.669 0.987053 15.091 1.40901C15.5129 1.83097 15.75 2.40326 15.75 3V5.25C15.75 5.44891 15.671 5.63968 15.5303 5.78033C15.3897 5.92098 15.1989 6 15 6C14.8011 6 14.6103 5.92098 14.4697 5.78033C14.329 5.63968 14.25 5.44891 14.25 5.25V3C14.25 2.80109 14.171 2.61032 14.0303 2.46967C13.8897 2.32902 13.6989 2.25 13.5 2.25H3C2.80109 2.25 2.61032 2.32902 2.46967 2.46967C2.32902 2.61032 2.25 2.80109 2.25 3V15C2.25 15.1989 2.32902 15.3897 2.46967 15.5303C2.61032 15.671 2.80109 15.75 3 15.75H13.5C13.6989 15.75 13.8897 15.671 14.0303 15.5303C14.171 15.3897 14.25 15.1989 14.25 15V12.75C14.25 12.5511 14.329 12.3603 14.4697 12.2197C14.6103 12.079 14.8011 12 15 12C15.1989 12 15.3897 12.079 15.5303 12.2197C15.671 12.3603 15.75 12.5511 15.75 12.75V15C15.75 15.5967 15.5129 16.169 15.091 16.591C14.669 17.0129 14.0967 17.25 13.5 17.25H3Z"
            fill="#525252"
          />
        </svg>
      </>
    );
  }
}
export default Logo;
