import React from "react";

export class Logo extends React.Component {
  render() {
    return (
      <>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M26.936 24.74C25.516 22.452 22.39 20 16 20C9.60996 20 6.48596 22.45 5.06396 24.74C6.37413 26.3825 8.0378 27.7084 9.93119 28.6191C11.8246 29.5298 13.8989 30.0018 16 30C18.101 30.0018 20.1754 29.5298 22.0687 28.6191C23.9621 27.7084 25.6258 26.3825 26.936 24.74Z"
            fill="#424040"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16 18C17.5913 18 19.1174 17.3679 20.2426 16.2426C21.3679 15.1174 22 13.5913 22 12C22 10.4087 21.3679 8.88258 20.2426 7.75736C19.1174 6.63214 17.5913 6 16 6C14.4087 6 12.8826 6.63214 11.7574 7.75736C10.6321 8.88258 10 10.4087 10 12C10 13.5913 10.6321 15.1174 11.7574 16.2426C12.8826 17.3679 14.4087 18 16 18Z"
            fill="#424040"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16 2C12.287 2 8.72601 3.475 6.1005 6.1005C3.475 8.72601 2 12.287 2 16C2 19.713 3.475 23.274 6.1005 25.8995C8.72601 28.525 12.287 30 16 30C19.713 30 23.274 28.525 25.8995 25.8995C28.525 23.274 30 19.713 30 16C30 12.287 28.525 8.72601 25.8995 6.1005C23.274 3.475 19.713 2 16 2ZM0 16C0 11.7565 1.68571 7.68687 4.68629 4.68629C7.68687 1.68571 11.7565 0 16 0C20.2435 0 24.3131 1.68571 27.3137 4.68629C30.3143 7.68687 32 11.7565 32 16C32 20.2435 30.3143 24.3131 27.3137 27.3137C24.3131 30.3143 20.2435 32 16 32C11.7565 32 7.68687 30.3143 4.68629 27.3137C1.68571 24.3131 0 20.2435 0 16Z"
            fill="#424040"
          />
        </svg>
      </>
    );
  }
}
export default Logo;
