import React from "react";
import "./MainBox.scss";
import {
  Container,
  Row,
  Col,
  FormControl,
  Button,
  InputGroup,
} from "react-bootstrap";
import IconLogo from "../IconSvg/IconLogo";
import IconAccess from "../IconSvg/IconAccess";
import IconModules from "../IconSvg/iconModules";
import IconGo from "../IconSvg/IconGo";

export default function MainBox(){
    return (
      <>
        <section className="MainBox">
          <section className="containerFull">           
            <div className="title-intern">
              <IconModules />
              Módulos
            </div>
            <Row>
              <Col md={3}>
                <section className="boxDefault">
                  <h2>
                    <IconLogo />
                    Cursos Livres
                  </h2>
                  <p>Disponibilize cursos para estudantes e colaboradores</p>
                  <a href="#">
                    <IconAccess />
                    Acessar
                  </a>
                </section>
              </Col>
              <Col md={3}>
                <section className="boxDefault">
                  <h2>
                    <IconLogo />
                    Integrador
                  </h2>
                  <p>
                  Faça o ensalamento de estudantes e colaboradores e gerencie seu campus virtual.
                  </p>
                  <a href="#">
                    <IconAccess />
                    Acessar
                  </a>
                </section>
              </Col>
              <Col md={3}>
                <section className="boxDefault">
                  <h2>
                    <IconLogo />
                    Roteiros
                  </h2>
                  <p>
                  Acesse os roteiros de aprendizagem preparados pelo time pedagógico.
                  </p>
                  <a href="#">
                    <IconAccess />
                    Acessar
                  </a>
                </section>
              </Col>
              <Col md={3}>
                <section className="boxDefault">
                  <h2>
                    <IconLogo />
                    Atendimento
                  </h2>
                  <p>
                    Facilite o agendamento de reuniões entre estudantes e a instituição dentro do Teams.
                  </p>
                  <a href="#">
                    <IconAccess />
                    Acessar
                  </a>
                </section>
              </Col>
            </Row>
          </section>
          <section className="nowModules">
            <div className="containerFull">
              <Row>
                <Col md={6}>
                  <a href="#">
                    <IconModules />
                    Gerenciar meus módulos</a>
                </Col> 
                <Col md={6}>
                <a href="#">
                  <IconGo />
                  Conheça os outros módulos Big Brain</a></Col> 
              </Row>
            </div>
          </section>
        </section>
      </>
    );
  }
