import React from "react";

export class Logo extends React.Component {
  render() {
    return (
      <>
        <svg
          width="15"
          height="16"
          viewBox="0 0 15 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.96866 7.50009H15V0.497778C15 0.365759 14.9508 0.239147 14.8633 0.145796C14.7758 0.0524443 14.6571 0 14.5333 0H7.96866V7.50009Z"
            fill="#4CAF50"
          />
          <path
            d="M7.03133 7.50009V0H0.466667C0.342899 0 0.2242 0.0524443 0.136684 0.145796C0.0491665 0.239147 0 0.365759 0 0.497778L0 7.49796L7.03133 7.50009Z"
            fill="#F44336"
          />
          <path
            d="M7.03133 8.5H0V15.5002C0 15.6322 0.0491665 15.7588 0.136684 15.8522C0.2242 15.9455 0.342899 15.998 0.466667 15.998H7.02933L7.03133 8.5Z"
            fill="#2196F3"
          />
          <path
            d="M7.96866 8.5V16.0001H14.5313C14.6551 16.0001 14.7738 15.9476 14.8613 15.8543C14.9488 15.7609 14.998 15.6343 14.998 15.5023V8.5H7.96866Z"
            fill="#FFC107"
          />
        </svg>
      </>
    );
  }
}
export default Logo;
