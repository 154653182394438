import React from "react";

export class Logo extends React.Component {
  render() {
    return (
      <>
        <svg
          width="22"
          height="31"
          viewBox="0 0 22 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18 15H4C3.46957 15 2.96086 15.2107 2.58579 15.5858C2.21071 15.9609 2 16.4696 2 17V27C2 27.5304 2.21071 28.0391 2.58579 28.4142C2.96086 28.7893 3.46957 29 4 29H18C18.5304 29 19.0391 28.7893 19.4142 28.4142C19.7893 28.0391 20 27.5304 20 27V17C20 16.4696 19.7893 15.9609 19.4142 15.5858C19.0391 15.2107 18.5304 15 18 15ZM4 13C2.93913 13 1.92172 13.4214 1.17157 14.1716C0.421427 14.9217 0 15.9391 0 17V27C0 28.0609 0.421427 29.0783 1.17157 29.8284C1.92172 30.5786 2.93913 31 4 31H18C19.0609 31 20.0783 30.5786 20.8284 29.8284C21.5786 29.0783 22 28.0609 22 27V17C22 15.9391 21.5786 14.9217 20.8284 14.1716C20.0783 13.4214 19.0609 13 18 13H4ZM4 7C4 5.14348 4.7375 3.36301 6.05025 2.05025C7.36301 0.737498 9.14348 0 11 0C12.8565 0 14.637 0.737498 15.9497 2.05025C17.2625 3.36301 18 5.14348 18 7V13H16V7C16 5.67392 15.4732 4.40215 14.5355 3.46447C13.5979 2.52678 12.3261 2 11 2C9.67392 2 8.40215 2.52678 7.46447 3.46447C6.52678 4.40215 6 5.67392 6 7V13H4V7Z"
            fill="#525252"
          />
        </svg>
      </>
    );
  }
}
export default Logo;
