import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { BsArrowLeft, BsArrowRight, BsX } from "react-icons/bs";
import styled from "styled-components";
import BrainzComboBox from "../../../components/BrainzComboBox/BrainzComboBox";
import { feedBack, isInvalidFeedBack, useValidation } from "../../../hooks/Validation";


interface ModalRegisterProps {
    activeModal: number,
    setActiveModal: React.Dispatch<React.SetStateAction<number>>,
    setPreviousActiveModal: React.Dispatch<React.SetStateAction<number>>
}

export default function RegisterInstitution(props: ModalRegisterProps) {

    const [stepAddInstitution, setStepAddInstitution] = useState(1);
    const [institutionName, setInstitutionName] = useState("");
    const [institutionCnpj, setInstitutionCnpj] = useState("");
    const [provider, setProvider] = useState<number>();
    const [buttonDisable, setButtonDisable] = useState<boolean>(true);
    const [validationForm, setValidationFormField, validationErrors, setNewValidationErrors] = useValidation();

    function handleInstitutionNameOnChange(value: string) {
        let masked = value.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
        setInstitutionName(masked)
    }
    function handleInstitutionCnpjOnChange(value: string) {
        //mascara CNPJ
        let masked = value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
        setInstitutionCnpj(masked)
    }
    function findErrorsServer() {
        let errors: any = {}

        if (validationForm.current) {
            if (!validationForm.current.institutionName || validationForm.current.institutionName === "")
                errors.institutionName = "Campo obrigatório"
            else errors.institutionName = undefined

            if (!validationForm.current.institutionCnpj || validationForm.current.institutionCnpj === "")
                errors.institutionCnpj = "Campo obrigatório"
            else errors.institutionCnpj = undefined
        }
        let result = setNewValidationErrors(errors)
        return result
    }
    function handlerNextStep2() {
        let existErrorConnectionString = findErrorsServer()
        if (!existErrorConnectionString) {
            setStepAddInstitution(2)
        }
    }
    useEffect(() => {
        updateValidation()
        function updateValidation() {
            validationForm.current = {
                "institutionName": institutionName,
                "institutionCnpj": institutionCnpj
            }
        }
    }, [institutionName, institutionCnpj])

    useEffect(() => {
        ClearData()
        function ClearData() {
            if (props.activeModal === 0) {
                setStepAddInstitution(1)
                setInstitutionName('')
                setInstitutionCnpj('')
                setProvider(0)
                setButtonDisable(true)
            }
        }
    }, [props.activeModal])


    return (

        <ModalRegisterStyles
            show={props.activeModal === 1}
            onHide={() => {
                props.setActiveModal(6);
                props.setPreviousActiveModal(1);
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title >
                    {stepAddInstitution === 1 &&
                        <Row><Col><h3>PASSO 1 DE 3</h3></Col></Row>
                    }
                    {stepAddInstitution === 2 &&
                        <Row><Col><h3>PASSO 2 DE 3</h3></Col></Row>
                    }
                    {stepAddInstitution === 3 &&
                        <Row><Col><h3>PASSO 3 DE 3</h3></Col></Row>
                    }
                    {stepAddInstitution === 1 &&
                        <Row><Col><h2> Informe os seguintes dados </h2></Col></Row>
                    }
                    {stepAddInstitution === 2 &&
                        <Row><Col><h2> Definição das aplicações do produto </h2></Col></Row>
                    }
                    {stepAddInstitution === 3 &&
                        <Row><Col><h2> Informações cadastrais </h2></Col></Row>
                    }
                </Modal.Title>
            </Modal.Header>
            {stepAddInstitution === 1 &&
                <Modal.Body className="mb-2">
                    <Form>
                        <Form.Group as={Row}>
                            <Form.Label column sm={12}>Para essa etapa vamos precisar definir um nome e CNPJ de identificação para a instituição e definir em qual produto as necessidades da instituição se adequa. </Form.Label>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={12}>Nome da instituição*</Form.Label>
                            <Col sm={12}>
                                <Form.Control placeholder="Digite o nome da instituição" size="sm" type="text"
                                    value={institutionName}
                                    isInvalid={isInvalidFeedBack("institutionName", validationErrors)}
                                    onChange={(e) => { handleInstitutionNameOnChange(e.target.value) }}
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">{feedBack("institutionName", validationErrors)}</Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={12}>CNPJ*</Form.Label>
                            <Col sm={12}>
                                <Form.Control placeholder="Digite o CNPJ da instituição" size="sm" type="text"
                                    value={institutionCnpj}
                                    isInvalid={isInvalidFeedBack("institutionCnpj", validationErrors)}
                                    onChange={(e) => { handleInstitutionCnpjOnChange(e.target.value) }}
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">{feedBack("institutionCnpj", validationErrors)}</Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mt-2">
                            <Form.Label column sm={12}>Provedor (Acesso Social)*</Form.Label>
                            <Col
                                className=""
                            >
                                <Form.Check
                                    className="button-radio mb-3"
                                    type="radio"
                                    label={"Microsoft"}
                                    checked={provider === 1}
                                    onChange={() => [setProvider(1), setButtonDisable(false)]}
                                ></Form.Check>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col
                                className=""
                            >
                                <Form.Check
                                    className="button-radio mb-3"
                                    type="radio"
                                    label={"Google"}
                                    checked={provider === 2}
                                    onChange={() => [setProvider(2), setButtonDisable(false)]}
                                ></Form.Check>
                            </Col>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            }
            {stepAddInstitution === 2 &&
                <Modal.Body className="mb-2">
                    <Form>
                        <Form.Group as={Row}>
                            <Form.Label column sm={12}>Confirme as aplicações necessárias. </Form.Label>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={12}>
                                Produto:
                            </Form.Label>
                            <Col sm={12}>
                                {/* <BrainzComboBox
                                        setValue={(val) => handleUnitIdentifierOnChange(val)}
                                        value={unitIdentifier}
                                        endpoint={`/v1/Unit/${institutionId}/List`}
                                        idField="identifier"
                                        valueField="name"
                                        placeholder="Selecione a unidade desta turma"
                                        isInvalid={isInvalidFeedBack("unitIdentifier", validationErrors)}

                                    ></BrainzComboBox> */}
                                <Form.Control.Feedback type="invalid">
                                    {/* {feedBack("unitIdentifier", validationErrors)} */}
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Row
                        //key={index}
                        >
                            <Col className="">
                                <Form.Check
                                    className="button-radio my-3"
                                    //checked={item.active === true}
                                    label={"nome "}
                                //onChange={() => setHandleDomainsList(index)}
                                ></Form.Check>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            }
            {stepAddInstitution === 3 &&
                <Modal.Body className="mb-2">
                    <Form>
                        <Form.Group as={Row}>
                            <Form.Label column sm={12}>Fique tranquilo, já estamos finalizando! Agora só precisamos preencher os seguintes dados.</Form.Label>
                        </Form.Group>
                    </Form>

                </Modal.Body>
            }

            {stepAddInstitution === 1 &&
                <Modal.Footer className="">
                    <Button
                        className="cancel-button"
                        onClick={() => {
                            props.setActiveModal(6);
                            props.setPreviousActiveModal(1);
                        }}
                    >
                        <BsX color="#A56300" size="24" className="mr-3 icon-close" />

                        Cancelar
                    </Button>
                    <Button
                        variant="primary"
                        className="continue-button"
                        disabled={buttonDisable}
                        onClick={() => handlerNextStep2()}
                    >
                        Continuar
                        <BsArrowRight className="ml-3" color="#Fff" size="24" />

                    </Button>
                </Modal.Footer>
            }
            {stepAddInstitution === 2 &&
                <Modal.Footer>
                    <div>
                        <Button
                            className="cancel-button-x"
                            onClick={() => {
                                props.setActiveModal(6);
                                props.setPreviousActiveModal(1);
                            }}
                        >
                            <BsX color="#A56300" size="24" className="icon-close" />

                        </Button>
                        <Button
                            variant="primary"
                            className="return-button ml-4"
                            onClick={() => setStepAddInstitution(1)}
                        >
                            <BsArrowLeft className="mr-2 bi bi-arrow-left" color="" size="24" />
                            Voltar
                        </Button>
                    </div>
                    <div>

                    </div>
                    <div>
                        <Button
                            variant="primary"
                            className="continue-button"
                            onClick={() => setStepAddInstitution(3)}
                        >
                            Continuar
                            <BsArrowRight className="ml-3" color="#Fff" size="24" />

                        </Button>
                    </div>
                </Modal.Footer>
            }
            {stepAddInstitution === 3 &&
                <Modal.Footer>
                    <div>
                        <Button
                            className="cancel-button-x"
                            onClick={() => {
                                props.setActiveModal(6);
                                props.setPreviousActiveModal(1);
                            }}
                        >
                            <BsX color="#A56300" size="24" className="icon-close" />

                        </Button>
                        <Button
                            variant="primary"
                            className="return-button ml-4"
                            onClick={() => setStepAddInstitution(2)}
                        >
                            <BsArrowLeft className="mr-2 bi bi-arrow-left" color="" size="24" />
                            Voltar
                        </Button>
                    </div>
                    <div>

                    </div>
                    <div>
                        <Button
                            variant="primary"
                            className="continue-button"
                        //onClick={() => setStepAddInstitution(3)}
                        >
                            Continuar
                            <BsArrowRight className="ml-3" color="#Fff" size="24" />

                        </Button>
                    </div>
                </Modal.Footer>
            }

        </ModalRegisterStyles>
    )
}

const ModalRegisterStyles = styled(Modal)`

.modal-header{
    margin: 10px;
    margin-bottom: -10px;
    border-bottom: none;
    h2{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 19px;
    }
    h3{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 16px;
    }
}
  .modal-dialog{
    margin: 0px auto;
    overflow-y: initial!important;
    height: 100%!important;
}
  .modal-content {
    width: 600px;
    height: 100%;
  }
  .modal-footer{
    margin: 10px;
    border-top: none;
    place-content: space-between;
    .button-confirm{
        
        width: 100%;
                height: 48px;
                background: #2155BA;
                border: 1px solid #2155BA;
                box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
                border-radius: 8px;
                &:hover{
                    color: #fff!important;
                background-color: #153D89!important;
                .icon-close{
                    fill: #fff!important;
                }
            }&:active{
                    color: #fff!important;
                background-color: #153D89!important;
                .icon-close{
                    fill: #fff!important;
                }
            }&:focus{
                    border: none !important;
            }
    }
    .cancel-button {
      width: 196px;
      height: 48px;
      color: #a56300;
      background-color: #fff;
      border: 1px solid #a56300;
      box-sizing: border-box;
      border-radius: 8px;
      &:hover {
        color: #fff !important;
        background-color: #a56300 !important;
        .icon-close {
          fill: #fff !important;
        }
      }
      &:active {
        color: #fff !important;
        background-color: #a56300 !important;
        .icon-close {
          fill: #fff !important;
        }
      }
      &:focus {
        border: none !important;
      }
    }
    .sucess-button {
      width: 190px;
      height: 48px;
      background: #008555;
      border: 1px solid #008555;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      &:hover {
        color: #fff !important;
        background-color: #153d89 !important;
        border: 1px solid #153d89;
        .icon-close {
          fill: #fff !important;
        }
      }
      &:active {
        color: #fff !important;
        background-color: #153d89 !important;
        .icon-close {
          fill: #fff !important;
        }
      }
      &:focus {
        border: none !important;
      }
    }
    .continue-button {
      width: 190px;
      height: 48px;
      background: #2155ba;
      border: 1px solid #2155ba;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      &:hover {
        color: #fff !important;
        background-color: #153d89 !important;
        border: 1px solid #153d89;
        .icon-close {
          fill: #fff !important;
        }
      }
      &:active {
        color: #fff !important;
        background-color: #153d89 !important;
        .icon-close {
          fill: #fff !important;
        }
      }
      &:focus {
        border: none !important;
      }
    }
    .return-button {
      margin-right: 10px !important;
      width: 107px;
      height: 48px;
      background: #fff;
      border: 1px solid #2155ba;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      color: #2155ba;
      &:hover {
        color: #fff !important;
        background-color: #153d89 !important;
        .icon-close {
          fill: #fff !important;
        }
      }
      &:active {
        color: #fff !important;
        background-color: #153d89 !important;
        .icon-close {
          fill: #fff !important;
        }
      }
      &:focus {
        border: none !important;
      }
    }
    .cancel-button-x {
      width: 48px;
      height: 48px;
      color: #a56300;
      background-color: #fff;
      border: 1px solid #a56300;
      box-sizing: border-box;
      border-radius: 8px;
      &:hover {
        color: #fff !important;
        background-color: #a56300 !important;
        .icon-close {
          fill: #fff !important;
        }
      }
      &:active {
        color: #fff !important;
        background-color: #a56300 !important;
        .icon-close {
          fill: #fff !important;
        }
      }
      &:focus {
        border: none !important;
      }
    }
}
  .modal-body {
    margin: 10px;
      margin-top:0px;
      padding-top: 0px;
    overflow-y: auto!important;
    overflow-x: clip!important;
    max-height: calc(100vh - 100px);
                .form-group{
                    margin-bottom: 0px;
}
                .form-control{
                    padding: 2px 16px 0px;
                width: 100%;
                height: 48px;
                left: 24px;
                top: 104px;
                background: #FFFFFF;
                border: 1px solid #A6A6A6;
                box-sizing: border-box;
                border-radius: 8px;
}
                .form-select-type{
                    padding: 10px;
                width: 100%;
                height: 48px;
                left: 24px;
                top: 104px;
                background: #FFFFFF;
                border: 1px solid #A6A6A6;
                box-sizing: border-box;
                border-radius: 8px;
}
.button-radio {
      margin-right: 10px;
      font-size: 16px;
      line-height: 24px;
      color: #6f6f6f;
      input {
        width: 32px;
        height: 32px;
      }
      label {
        padding-left: 20px;
        padding-top: 10px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
      }
    }
                  


                `