import React from "react";
import {
  Container,
  Row,
  Col,
  FormControl,
  Button,
  InputGroup,
} from "react-bootstrap";
import SideMenuToggle from "./SideMenuToggle";
import { BsBellFill, BsSearch } from "react-icons/bs";
import IconLogo from "../IconSvg/IconLogo";
import IconModules from "../IconSvg/iconModules";
import IconProjects from "../IconSvg/iconProjects";
import IconSchool from "../IconSvg/iconSchool";
import IconRelationchip from "../IconSvg/iconRelationchip";
import IconModulesTop from "../IconSvg/IconModulesTop";
import IconLock from "../IconSvg/IconLock";
import IconSupport from "../IconSvg/IconSupport";
import IconUser from "../IconSvg/IconUser";
import IconUserTop from "../IconSvg/IconUserTop";
import { NavLink } from "react-router-dom";

export class Header extends React.Component {
  render() {
    return (
      <header>
        <Container className="container-header" fluid={true}>
          <Row>
            <Col md={3}>
              <a href='home' className="text-decoration-none">
                <h4 className="text-dark">
                  <IconLogo />
                  Central Big Brain
                </h4>
              </a>

            </Col>
            <Col md={7}>
              <nav className="nav-default">
                <ul>
                  <li>
                    <a href="#">
                      <IconModules />
                      Módulos
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <IconProjects />
                      Projetos
                    </a>
                  </li>
                  <li>
                    <a href="#">Documentos</a>
                  </li>
                  <li>
                    <NavLink to='/institutions'>
                      <IconSchool />
                      Instituição
                    </NavLink>
                  </li>
                  <li>
                    <a href="#">
                      <IconRelationchip />
                      Relacionamento
                    </a>
                  </li>
                </ul>
              </nav>
            </Col>
            <Col md={2}>
              <nav className="nav-right">
                <ul>
                  <li>
                    <a href="#">
                      <IconModulesTop />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <IconSupport />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <IconUserTop />
                    </a>
                  </li>
                </ul>
              </nav>
            </Col>
          </Row>
        </Container>
      </header>
    );
  }
}

export default Header;
