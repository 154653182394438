import styled from "styled-components"
import { Button, Col, Modal, Row } from 'react-bootstrap';
interface ConfirmModalProps {
    show: boolean,
    onHide: () => void,
    title: string,
    subTitle: string,
    confirmText: string,
    confirmAction: () => void,
    refuseText: string,
    refuseAction: () => void,
    principalIcon: React.ReactNode
}

export default function ConfirmModal(props: ConfirmModalProps) {
    if (props.show)
        return (
            <ConfirmModalStyle show={props.show} onHide={props.onHide}>
                <Modal.Body>
                    <Row className="my-4">
                        <Col>
                            {props.principalIcon}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h3> {props.title}</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>
                                {props.subTitle}
                            </p>

                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <Button className="button-active mr-1" variant="primary"
                            onClick={() => props.confirmAction()}
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="#A56300" className="mr-2 icon-x" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.781 6.219C17.8508 6.28867 17.9062 6.37143 17.944 6.46255C17.9818 6.55367 18.0013 6.65135 18.0013 6.75C18.0013 6.84865 17.9818 6.94633 17.944 7.03745C17.9062 7.12857 17.8508 7.21133 17.781 7.281L7.28097 17.781C7.14014 17.9218 6.94913 18.0009 6.74997 18.0009C6.55081 18.0009 6.3598 17.9218 6.21897 17.781C6.07814 17.6402 5.99902 17.4492 5.99902 17.25C5.99902 17.0508 6.07814 16.8598 6.21897 16.719L16.719 6.219C16.7886 6.14915 16.8714 6.09374 16.9625 6.05593C17.0536 6.01812 17.1513 5.99866 17.25 5.99866C17.3486 5.99866 17.4463 6.01812 17.5374 6.05593C17.6285 6.09374 17.7113 6.14915 17.781 6.219Z" fill="#A56300" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.21888 6.219C6.14903 6.28867 6.09362 6.37143 6.05581 6.46255C6.018 6.55367 5.99854 6.65135 5.99854 6.75C5.99854 6.84865 6.018 6.94633 6.05581 7.03745C6.09362 7.12857 6.14903 7.21133 6.21888 7.281L16.7189 17.781C16.8597 17.9218 17.0507 18.0009 17.2499 18.0009C17.449 18.0009 17.64 17.9218 17.7809 17.781C17.9217 17.6402 18.0008 17.4492 18.0008 17.25C18.0008 17.0508 17.9217 16.8598 17.7809 16.719L7.28088 6.219C7.21121 6.14915 7.12844 6.09374 7.03733 6.05593C6.94621 6.01812 6.84853 5.99866 6.74988 5.99866C6.65122 5.99866 6.55354 6.01812 6.46243 6.05593C6.37131 6.09374 6.28854 6.14915 6.21888 6.219Z" fill="#A56300" />
                            </svg>
                            {props.confirmText}
                        </Button>

                        <Button className="button-cancel" variant="primary"
                            onClick={() => props.refuseAction()}
                        >
                            {props.refuseText}
                        </Button>

                    </div>
                </Modal.Footer>
            </ConfirmModalStyle>

        )
    else return <></>
}



const ConfirmModalStyle = styled(Modal)` 
text-align: center;
font-style: normal;

h3{
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #514F4E;
}
p{
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #636060;
}
.modal-footer{
    border-top: 0px solid!important;
}
.button-cancel{
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    width: 229px;
    height: 48px;
    background: #2155BA;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}
.button-active{
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #A56300;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    width: 229px;
    height: 48px;
    border: 1px solid #A56300;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: #fff;
    &:active{
        color: #2155BA!important;
        background: #fff!important;
    }
    &:hover{
        color: #fff!important;
        background: #A56300!important;
        .icon-x{
            color: #fff!important;
            //background: #A56300!important;
        }
    }
    &:focus{
        box-shadow:none!important;
    }
    &:active{
        box-shadow:none!important;
    }
}
`
