import React from "react";

export class Logo extends React.Component {
  render() {
    return (
      <>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="24" height="24" fill="#E5E5E5" />
          <rect
            width="1280"
            height="1029"
            transform="translate(-694 -22)"
            fill="#F9F9F9"
          />
          <rect x="-694" y="-22" width="1280" height="68" fill="#EAEAEA" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M22.9275 0.133229C23.0271 0.202295 23.1084 0.294454 23.1646 0.401819C23.2207 0.509185 23.25 0.62856 23.25 0.749729V23.2497C23.25 23.4486 23.171 23.6394 23.0303 23.7801C22.8897 23.9207 22.6989 23.9997 22.5 23.9997H18C17.8011 23.9997 17.6103 23.9207 17.4697 23.7801C17.329 23.6394 17.25 23.4486 17.25 23.2497V20.9997H15.75V23.2497C15.75 23.4486 15.671 23.6394 15.5303 23.7801C15.3897 23.9207 15.1989 23.9997 15 23.9997H1.5C1.30109 23.9997 1.11032 23.9207 0.96967 23.7801C0.829018 23.6394 0.75 23.4486 0.75 23.2497V14.2497C0.750082 14.0722 0.813135 13.9005 0.927945 13.765C1.04275 13.6296 1.20187 13.5393 1.377 13.5102L9.75 12.1152V5.24973C9.75 5.09718 9.79652 4.94825 9.88336 4.82282C9.97019 4.69739 10.0932 4.60143 10.236 4.54773L22.236 0.0477286C22.3494 0.00522628 22.4713 -0.00919653 22.5915 0.0056926C22.7117 0.0205817 22.8264 0.0643401 22.926 0.133229H22.9275ZM11.25 5.76873V12.7497C11.2499 12.9273 11.1869 13.099 11.0721 13.2344C10.9572 13.3698 10.7981 13.4601 10.623 13.4892L2.25 14.8842V22.4997H14.25V20.2497C14.25 20.0508 14.329 19.8601 14.4697 19.7194C14.6103 19.5787 14.8011 19.4997 15 19.4997H18C18.1989 19.4997 18.3897 19.5787 18.5303 19.7194C18.671 19.8601 18.75 20.0508 18.75 20.2497V22.4997H21.75V1.83273L11.25 5.76873Z"
            fill="#424040"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.75 23.25V12.75H11.25V23.25H9.75Z"
            fill="#424040"
          />
          <path
            d="M3.75 16.5H5.25V18H3.75V16.5ZM6.75 16.5H8.25V18H6.75V16.5ZM3.75 19.5H5.25V21H3.75V19.5ZM6.75 19.5H8.25V21H6.75V19.5ZM15.75 4.5H17.25V6H15.75V4.5ZM18.75 4.5H20.25V6H18.75V4.5ZM12.75 7.5H14.25V9H12.75V7.5ZM15.75 7.5H17.25V9H15.75V7.5ZM18.75 7.5H20.25V9H18.75V7.5ZM15.75 10.5H17.25V12H15.75V10.5ZM18.75 10.5H20.25V12H18.75V10.5ZM12.75 10.5H14.25V12H12.75V10.5ZM12.75 13.5H14.25V15H12.75V13.5ZM15.75 13.5H17.25V15H15.75V13.5ZM18.75 13.5H20.25V15H18.75V13.5ZM12.75 16.5H14.25V18H12.75V16.5ZM15.75 16.5H17.25V18H15.75V16.5ZM18.75 16.5H20.25V18H18.75V16.5Z"
            fill="#424040"
          />
        </svg>
      </>
    );
  }
}
export default Logo;
