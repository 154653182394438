import React from "react";

export class Logo extends React.Component {
  render() {
    return (
      <>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.25 1.5H3.75C3.35218 1.5 2.97064 1.65804 2.68934 1.93934C2.40804 2.22064 2.25 2.60218 2.25 3V21C2.25 21.3978 2.40804 21.7794 2.68934 22.0607C2.97064 22.342 3.35218 22.5 3.75 22.5H20.25C20.6478 22.5 21.0294 22.342 21.3107 22.0607C21.592 21.7794 21.75 21.3978 21.75 21V3C21.75 2.60218 21.592 2.22064 21.3107 1.93934C21.0294 1.65804 20.6478 1.5 20.25 1.5ZM3.75 0C2.95435 0 2.19129 0.316071 1.62868 0.87868C1.06607 1.44129 0.75 2.20435 0.75 3V21C0.75 21.7956 1.06607 22.5587 1.62868 23.1213C2.19129 23.6839 2.95435 24 3.75 24H20.25C21.0456 24 21.8087 23.6839 22.3713 23.1213C22.9339 22.5587 23.25 21.7956 23.25 21V3C23.25 2.20435 22.9339 1.44129 22.3713 0.87868C21.8087 0.316071 21.0456 0 20.25 0L3.75 0Z"
            fill="#424040"
          />
          <path
            d="M12.75 3H11.25C10.4216 3 9.75 3.67157 9.75 4.5V9C9.75 9.82843 10.4216 10.5 11.25 10.5H12.75C13.5784 10.5 14.25 9.82843 14.25 9V4.5C14.25 3.67157 13.5784 3 12.75 3Z"
            fill="#424040"
          />
          <path
            d="M6.75 3H5.25C4.42157 3 3.75 3.67157 3.75 4.5V15C3.75 15.8284 4.42157 16.5 5.25 16.5H6.75C7.57843 16.5 8.25 15.8284 8.25 15V4.5C8.25 3.67157 7.57843 3 6.75 3Z"
            fill="#424040"
          />
          <path
            d="M18.75 3H17.25C16.4216 3 15.75 3.67157 15.75 4.5V19.5C15.75 20.3284 16.4216 21 17.25 21H18.75C19.5784 21 20.25 20.3284 20.25 19.5V4.5C20.25 3.67157 19.5784 3 18.75 3Z"
            fill="#424040"
          />
        </svg>
      </>
    );
  }
}
export default Logo;
