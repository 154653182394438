import Cookies from 'universal-cookie';
import LayoutDefault from './components/layouts/LayoutDefault'
import AccountInterface from './AccountInterface';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Toast from './components/Common/Toast';
import PageLoader from './components/Common/Loader'

import { useState, useEffect } from 'react'
import Institutions from './screens/Institutions/Institutions';
import Home from './screens/Home/Home';
import NotFound from './screens/NotFound/NotFound';

function Routes() {
  const cookies = new Cookies();
  const [loading, setLoading] = useState(true)

  function useQuery() {
    return new URLSearchParams(window.location.search);
  }

  let query = useQuery().get(String(process.env.REACT_APP_BRAINZ_NAME_TOKEN));
  
  if (query !== null && query !== "") {
    cookies.set(String(process.env.REACT_APP_BRAINZ_NAME_TOKEN), query, { path: "/" });
    window.location.href = window.location.origin
  }

  useEffect(() => {
    function pageLoad() {
      let token = cookies.get(String(process.env.REACT_APP_BRAINZ_NAME_TOKEN));
      if (token === undefined) {
        window.location.href = process.env["REACT_APP_ACCOUNT_REDIRECT"]!;
        return (
          <PageLoader></PageLoader>
        );
      }
      else {
        AccountInterface.get("/v1/Account/Authorization/" + process.env["REACT_APP_APPLICATION_ID"] + "/RefreshToken").then((result) => {
          cookies.set(String(process.env.REACT_APP_BRAINZ_NAME_TOKEN), result.data.result.token, { path: "/" });
          setLoading(false);
        });
      }
    }
    pageLoad()
  }, [])



  return (
    <Router>
      <Switch>
        <Route key={1} path="*" >
          {
            loading ? <PageLoader></PageLoader> :
              <LayoutDefault>
                <Switch>
                  {/* <Route key={1} path="" component={Home} />       */}
                  <Route key={2} path="/Home" component={Home} />            
                  <Route key={3} path="/Institutions" component={Institutions} />                  
                  <Route key={9} path="/" component={Home} />
                  <Route key={10} path="*" component={NotFound} />           
                </Switch>
              </LayoutDefault>
          }
        </Route>
      </Switch>
      <Toast></Toast>
    </Router>
  );
}

export default Routes;