import React from 'react';
import Footer from './Footer';
import Header from './Header';
import MainBox from '../MainBox/MainBox';
import InfoUser from '../InfoUser/InfoUser';
import ProjectsActive from '../ProjectsActive/ProjectsActive';


export class LayoutDefault extends React.Component {

  render() {
    return (
      <div className="App">
        <Header />
        {this.props.children}
         {/* <InfoUser />
        <MainBox />
        <ProjectsActive /> */}
      </div>
    )
  }
}
export default LayoutDefault;
