import React from "react";

export class Logo extends React.Component {
  render() {
    return (
      <>
        <svg
          width="22"
          height="24"
          viewBox="0 0 22 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.5 3H15.5C16.2956 3 17.0587 3.31607 17.6213 3.87868C18.1839 4.44129 18.5 5.20435 18.5 6V21C18.5 21.7956 18.1839 22.5587 17.6213 23.1213C17.0587 23.6839 16.2956 24 15.5 24H3.5C2.70435 24 1.94129 23.6839 1.37868 23.1213C0.816071 22.5587 0.5 21.7956 0.5 21V6C0.5 5.20435 0.816071 4.44129 1.37868 3.87868C1.94129 3.31607 2.70435 3 3.5 3ZM3.5 4.5C3.10218 4.5 2.72064 4.65804 2.43934 4.93934C2.15804 5.22064 2 5.60218 2 6V21C2 21.3978 2.15804 21.7794 2.43934 22.0607C2.72064 22.342 3.10218 22.5 3.5 22.5H15.5C15.8978 22.5 16.2794 22.342 16.5607 22.0607C16.842 21.7794 17 21.3978 17 21V6C17 5.60218 16.842 5.22064 16.5607 4.93934C16.2794 4.65804 15.8978 4.5 15.5 4.5H3.5Z"
            fill="#424040"
          />
          <path
            d="M6.5 0H18.5C19.2956 0 20.0587 0.316071 20.6213 0.87868C21.1839 1.44129 21.5 2.20435 21.5 3V18C21.5 18.7956 21.1839 19.5587 20.6213 20.1213C20.0587 20.6839 19.2956 21 18.5 21V19.5C18.8978 19.5 19.2794 19.342 19.5607 19.0607C19.842 18.7794 20 18.3978 20 18V3C20 2.60218 19.842 2.22064 19.5607 1.93934C19.2794 1.65804 18.8978 1.5 18.5 1.5H6.5C6.10218 1.5 5.72064 1.65804 5.43934 1.93934C5.15804 2.22064 5 2.60218 5 3H3.5C3.5 2.20435 3.81607 1.44129 4.37868 0.87868C4.94129 0.316071 5.70435 0 6.5 0V0Z"
            fill="#424040"
          />
        </svg>
      </>
    );
  }
}
export default Logo;
